require '../vendor/picker'
require '../vendor/picker.date'
require '../vendor/picker.time'
require '../vendor/picker.localizations'
import abidePhoneNumberInputValidator from '../utils/abide-phone-number-input-validator'

window.App ||= {}

App.init = ->
  if window.matchMedia('(pointer: coarse)').matches
    # this device is probably a touch device
    $('body').addClass('touch')
  else
    $('body').addClass('no-touch')

  Foundation.Abide.defaults.validators['selected_value'] =
    window.abideSelectedValue

  Foundation.Abide.defaults.validators['phone_number_input'] = abidePhoneNumberInputValidator

  $(document).foundation()

  Ladda.bind('.ladda')

  $('[data-behavior~=facebook-share]').click (e) ->
    e.preventDefault()
    FB.ui {
      method: 'share'
      display: 'popup'
      href: $(this).data('url')
    }, (response) ->

  $('[data-href]').on 'click', ->
    window.location.href = $(this).data('href')

$(document).ready ->
  App.init()
