import dialogPolyfill from 'dialog-polyfill'

document.addEventListener(`DOMContentLoaded`, () => {
  if(!window.HTMLDialogElement){
    document.querySelectorAll('dialog').forEach(x => {
      dialogPolyfill.registerDialog(x);
      x.classList.add(`polyfilled-dialog`)
    })
  }
})
