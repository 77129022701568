import $ from 'jquery'
window.jQuery = $
window.$ = $

import Rails from 'rails-ujs'

window.rails = Rails

# Load Stimulus controller files
import './controllers/public'
import './controllers/shared'

require './coffee/cookie-management.coffee'

import 'foundation-sites'
import './utils/dialog-polyfill'

window.SignaturePad = SignaturePad = require '/node_modules/signature_pad/dist/signature_pad'

require './vendor/picker'
require './vendor/picker.date'

require './coffee/abide.validators.coffee'

require './coffee/init.public.coffee'
require './coffee/app.helpers.coffee'
require './coffee/app.discount.coffee'
require './coffee/app.form.coffee'
require './ported-coffeescript/app.date_picker'
require './ported-coffeescript/app.time_picker'
require './ported-coffeescript/app.stripe_elements'
require './coffee/app.punchpass_payment_form.coffee'
require './coffee/app.signature_pad.coffee'
require './coffee/app.confirm.coffee'

if typeof(window.appHelpers) == 'undefined'
  window.appHelpers = new App.Helpers()

handleConfirm = (event) ->
  new App.Confirm(Rails, event)

Rails.delegate(document, 'form[data-confirm-modal]', 'submit', handleConfirm)
Rails.delegate(document, 'a[data-confirm-modal]', 'click', handleConfirm)
Rails.delegate(document, 'button[data-confirm-modal]', 'click', handleConfirm)

Rails.start()
