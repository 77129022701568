import { Controller } from '@hotwired/stimulus'
import intlTelInput from 'intl-tel-input'
import { en, zh, nl, fr, de, ja, no, es, sv } from "intl-tel-input/i18n"

//['zh-TW', 'nl', 'fr', 'de', 'ja', 'no', 'es', 'sv']

export default class extends Controller {
  static values = {
    requiredErrorMessage: String,
    invalidNumberErrorMessage: String,
    invalidCountryCodeErrorMessage: String,
    tooShortErrorMessage: String,
    tooLongErrorMessage: String,
  }
  static targets = ["input"]

  async inputTargetConnected() {
    await this.initializeIntlTelInput();
  }

  async initializeIntlTelInput() {
    this.intlTelInputInstance = await intlTelInput(this.inputTarget, {
      loadUtils: () => import("intl-tel-input/utils"),
      separateDialCode: true,
      locale: this.locale,
      initialCountry: "auto",
      geoIpLookup: callback => {
        fetch("https://ipapi.co/json")
          .then(res => res.json())
          .then(data => callback(data.country_code))
          .catch(() => callback("us"));
      }
    })

    this.errorElement.dataset['formErrorFor'] = this.inputTarget.id

    // here, the index maps to the error code returned from getValidationError - see readme
    this.errorMap = [
      this.invalidNumberErrorMessageValue,
      this.invalidCountryCodeErrorMessageValue,
      this.tooShortErrorMessageValue,
      this.tooLongErrorMessageValue,
      this.invalidNumberErrorMessageValue
    ]

    const validationEventHandler = this.validatePhoneNumber.bind(this)

    this.element.addEventListener(`change`, validationEventHandler)
    this.element.addEventListener(`countrychange`, validationEventHandler)
    this.inputTarget.form.addEventListener(`formdata`, this.serializeIntoFormDataHandler.bind(this))
  }

  async serializeIntoFormDataHandler(event) {
    const formData = event.formData;
    formData.delete(this.inputTarget.name)
    formData.set(this.inputTarget.name, this.intlTelInputInstance.getNumber())
  }

  validatePhoneNumber() {
    this.resetErrorState()
    if(this.inputTarget.value.trim() == "" && !this.isRequired){ return }
    if(this.isInAbideForm && this.isRequired && this.inputTarget.value.trim() == "") {
      this.setError(this.requiredErrorMessageValue)
      return
    }

    if(this.intlTelInputInstance.isValidNumber()) { return }

    const errorCode = this.intlTelInputInstance.getValidationError();
    const errorMessage = this.errorMap[errorCode] || this.invalidNumberErrorMessageValue || "Invalid Number";
    this.setError(errorMessage)
  }

  setError(errorMessage) {
    this.inputTarget.setCustomValidity(errorMessage)

    if(this.isInAbideForm){
      this.setAbideErrorMessage()
    } else {
      this.inputTarget.reportValidity()
    }
  }

  resetErrorState() {
    this.inputTarget.setCustomValidity("")
  }

  setAbideErrorMessage(){
    if(!this.isInAbideForm) { return }
    this.errorElement.textContent = this.errorMessage
  }

  get labelElement() {
    return this.element.querySelector(`label`)
  }

  get errorElement() {
    return this.element.querySelector(`.form-error`)
  }

  get errorMessage() {
    return this.inputTarget.validationMessage
  }

  get isValid() {
    return this.errorMessage == ''
  }

  get isInAbideForm() {
    return this.inputTarget.form.hasAttribute(`data-abide`)
  }

  get isRequired() {
    return this.inputTarget.required
  }

  get locale() {
    switch(window.LOCALE) {
      case 'zh-TW':
        return zh
      case 'nl':
        return nl;
      case 'fr':
        return fr;
      case 'de':
        return de;
      case 'ja':
        return ja;
      case 'no':
        return no;
      case 'es':
        return es;
      case 'sv':
        return sv;
      default:
        return en;
    }
  }
}
