import { ApplicationController } from './application_controller'

async function dialogToggledEvent(event) {
  let modalDialog = event?.target
  let modalDialogID = modalDialog?.id
  if(!modalDialogID){ return }

  let currentURL = new URL(window.location.href)

  if(modalDialog.open){
    currentURL.searchParams.set(`navigable_dialog_id`, modalDialogID)
  } else {
    currentURL.searchParams.delete(`navigable_dialog_id`)
  }
  window.history.replaceState({modalDialogID: modalDialogID}, "", currentURL.toString())
}

export default class extends ApplicationController {
  static values = {
    open: Boolean
  }

  connect(){
    if(!this.eventConnected) {
      this.element.addEventListener(`toggle`, dialogToggledEvent)
      this.eventConnected = true

      if(this.element.open){
        this.element.close()
        this.element.showModal()
      }
    }
  }
}
